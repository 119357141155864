<template>
  <div>
    <div>
      <a-menu
        theme="light"
        mode="inline"
        class="main-nav"
        :default-selected-keys="defaultSelectedKeys"
        :selected-keys="selectedKeys"
        @click="handleMenuClick"
      >
        <a-menu-item key="DASHBOARD" class="nav-text">
          <AppstoreFilled
            :style="
              selectedMenuItemIndex === 'dashboard'
                ? 'color:#4F4CFF;'
                : 'color:#FFFFFF'
            "
          />
          <span :style="collapsed ? 'display: none;' : 'margin-left: 10px;'"
            >DASHBOARD</span
          >
        </a-menu-item>
        <a-menu-item key="CANDIDATES" class="nav-text">
          <UserOutlined
            :style="
              selectedMenuItemIndex === 'candidates'
                ? 'color:#4F4CFF;'
                : 'color:#FFFFFF'
            "
          />
          <span :style="collapsed ? 'display: none;' : 'margin-left: 10px;'"
            >CANDIDATES</span
          >
        </a-menu-item>
        <a-menu-item key="ACCOUNTS" class="nav-text">
          <BankFilled
            :style="
              selectedMenuItemIndex === 'accounts'
                ? 'color:#4F4CFF;'
                : 'color:#FFFFFF'
            "
          />
          <span :style="collapsed ? 'display: none;' : 'margin-left: 10px;'"
            >ACCOUNTS</span
          >
        </a-menu-item>
        <a-menu-item key="WORKBENCH" class="nav-text">
          <DatabaseFilled
            :style="
              selectedMenuItemIndex === 'vacancy'
                ? 'color:#4F4CFF;'
                : 'color:#FFFFFF'
            "
          />
          <span :style="collapsed ? 'display: none;' : 'margin-left: 10px;'"
            >WORKBENCH</span
          >
        </a-menu-item>
        <a-menu-item key="MASTERS" class="nav-text">
          <DatabaseOutlined
            :style="
              selectedMenuItemIndex === 'master'
                ? 'color:#4F4CFF;'
                : 'color:#FFFFFF'
            "
          />
          <span :style="collapsed ? 'display: none;' : 'margin-left: 10px;'"
            >MASTERS</span
          >
        </a-menu-item>
        <a-menu-item key="REPORTS" class="nav-text">
          <ReconciliationOutlined
            :style="
              selectedMenuItemIndex === 'reports'
                ? 'color:#4F4CFF;'
                : 'color:#FFFFFF'
            "
          />
          <span :style="collapsed ? 'display: none;' : 'margin-left: 10px;'"
            >REPORTS</span
          >
        </a-menu-item>
        <a-menu-item key="BILLING" class="nav-text">
          <FileProtectOutlined
            :style="
              selectedMenuItemIndex === 'billing'
                ? 'color:#4F4CFF;'
                : 'color:#FFFFFF'
            "
          />
          <span :style="collapsed ? 'display: none;' : 'margin-left: 10px;'"
            >BILLING</span
          >
        </a-menu-item>
        <a-menu-item key="TARGET" class="nav-text">
          <ArrowsAltOutlined 
            :style="
              selectedMenuItemIndex === 'target'
                ? 'color:#4F4CFF;'
                : 'color:#FFFFFF'
            "
          />
          <span :style="collapsed ? 'display: none;' : 'margin-left: 10px;'"
            >TARGET</span
          >
        </a-menu-item>
        
      </a-menu>
    </div>
  </div>
</template>

<script>
import {
  AppstoreFilled,
  UserOutlined,
  BankFilled,
  DatabaseFilled,
  DatabaseOutlined,
  ReconciliationOutlined,
  FileProtectOutlined,
  ArrowsAltOutlined
} from "@ant-design/icons-vue";
export default {
  components: {
    AppstoreFilled,
    UserOutlined,
    BankFilled,
    DatabaseFilled,
    DatabaseOutlined,
    ReconciliationOutlined,
    FileProtectOutlined,
    ArrowsAltOutlined
  },
  props: {
    collapsed: Boolean,
  },
  computed: {
    pathname() {
      return this.$route.path;
    },
  },
  data() {
    return {
      role: "COMAPANY",
      selectedMenuItemIndex: null,
      defaultSelectedKeys: [],
      selectedKeys: [],
    };
  },

  mounted() {
    this.menuNavigation();
  },
  methods: {
    menuNavigation() {
      const currentPath = this.$route.path;
      if (currentPath.includes("/candidates")) {
        this.selectedMenuItemIndex = "candidates";
        this.defaultSelectedKeys = ["CANDIDATES"];
        this.selectedKeys = ["CANDIDATES"];
      } else if (currentPath.includes("/dashboard")) {
        this.selectedMenuItemIndex = "dashboard";
        this.defaultSelectedKeys = ["DASHBOARD"];
        this.selectedKeys = ["DASHBOARD"];
      } else if (currentPath.includes("/master")) {
        this.selectedMenuItemIndex = "master";
        this.defaultSelectedKeys = ["MASTERS"];
        this.selectedKeys = ["MASTERS"];
      } else if (currentPath.includes("/list-companies")) {
        this.selectedMenuItemIndex = "accounts";
        this.defaultSelectedKeys = ["ACCOUNTS"];
        this.selectedKeys = ["ACCOUNTS"];
      } else if (currentPath.includes("/vacancy/vacancy-list")) {
        this.selectedMenuItemIndex = "vacancy";
        this.defaultSelectedKeys = ["WORKBENCH"];
        this.selectedKeys = ["WORKBENCH"];
      } else if (currentPath.includes("/reports/listing")) {
        this.selectedMenuItemIndex = "reports";
        this.defaultSelectedKeys = ["REPORTS"];
        this.selectedKeys = ["REPORTS"];
      }else if (currentPath.includes("/billing")) {
        this.selectedMenuItemIndex = "billing";
        this.defaultSelectedKeys = ["BILLING"];
        this.selectedKeys = ["BILLING"];
      }
      else if (currentPath.includes("/target")) {
        this.selectedMenuItemIndex = "target";
        this.defaultSelectedKeys = ["TARGET"];
        this.selectedKeys = ["TARGET"];
      }
      
    },
    handleMenuClick(e) {
      const currentPath = this.$route.path;
      this.current = e.key;
      this.$emit("onMenuClick", e.key);
      if (e.key === "CANDIDATES") {
        this.selectedMenuItemIndex = "candidates";
        this.defaultSelectedKeys = ["CANDIDATES"];
        this.selectedKeys = ["CANDIDATES"];
        this.$router.push("/candidates");
      } else if (e.key === "DASHBOARD") {
        this.selectedMenuItemIndex = "dashboard";
        this.defaultSelectedKeys = ["DASHBOARD"];
        this.selectedKeys = ["DASHBOARD"];
        this.$router.push("/dashboard");
      } else if (e.key === "MASTERS") {
        this.selectedMenuItemIndex = "master";
        this.defaultSelectedKeys = ["MASTERS"];
        this.selectedKeys = ["MASTERS"];
        this.$router.push("/master");
      } else if (e.key === "ACCOUNTS") {
        this.selectedMenuItemIndex = "accounts";
        this.defaultSelectedKeys = ["ACCOUNTS"];
        this.selectedKeys = ["ACCOUNTS"];
        this.$router.push("/list-companies");
      } else if (e.key === "WORKBENCH") {
        this.selectedMenuItemIndex = "vacancy";
        this.defaultSelectedKeys = ["WORKBENCH"];
        this.selectedKeys = ["WORKBENCH"];
        this.$router.push("/vacancy/vacancy-list");
      } else if (e.key === "REPORTS") {
        this.selectedMenuItemIndex = "reports";
        this.defaultSelectedKeys = ["REPORTS"];
        this.selectedKeys = ["REPORTS"];
        this.$router.push("/reports/listing");
      } else if (e.key === "BILLING") {
        this.selectedMenuItemIndex = "billing";
        this.defaultSelectedKeys = ["BILLING"];
        this.selectedKeys = ["BILLING"];
        this.$router.push("/billing");
      }
      else if (e.key === "TARGET") {
        this.selectedMenuItemIndex = "target";
        this.defaultSelectedKeys = ["TARGET"];
        this.selectedKeys = ["TARGET"];
        this.$router.push("/target");
      }
      
    },
  },
};
</script>

<style lang="scss">
@import "../styles/leftNavigation.scss";
</style>
