const CANDIDATE_PROFILE = "/candidates/:id/detail";
const LIST_CANDIDATES = "/candidates";
const CREATE_CANDIDATE = "/candidates";
const CANDIDATE_TAXONOMY = "/candidates/:id/info";
const CANDIDATE_NOTES = "/candidates/:id/notes";
const CANDIDATE_WIDGETS = "/candidates/:id/widgets";
const CANDIDATE_MEETINGS = "/candidates/:id/meetings";
const CANDIDATE_DOCUMENTS = "candidates/:id/documents";
const CANDIDATE_TAGGINGS = "candidates/:id/taggings";
const CANDIDATE_VACANCIES = "candidates/:id/vacancies";
const COUNTIES = "/countries";
const MASTER = "/master";
const TEAM_LISTING = "/users";
const ATTACH_TO_VACANCY = "vacancies/:id/candidates/attach";
const SHOW_VACANCY_PROFILE = "candidates/:id/vacancies/stage/info";
const CREATE_GROUP = "/groups";
const CREATE_GROUP_LIST = "/groups";
const ADD_CANDIDATE_EXPERIENCE = "candidates/:id/experiences";
const DELETE_CANDIDATE_EXPERIENCE = "candidates/:id/experiences/:experienceId";
const EDIT_CANDIDATE_EXPERIENCE = "candidates/:id/experiences/:experienceId";
const EDIT_CANDIDATE_EDUCATION =
  "candidates/:id/qualifications/:qualificationId";
const ADD_CANDIDATE_EDUCATION = "candidates/:id/qualifications";
const DELETE_CANDIDATE_EDUCATION =
  "candidates/:id/qualifications/:qualificationId";
const EDIT_CANDIDATE = "candidates/:id";
const DASHBOARD_REPORTS = "/reports/dashboard";
const RESUME_REVIEW_REPORTS = "/reports/resumes-review";
const LANDSCAPE_REPORTS = "/reports/landscape";

const REPORTS = {
  DASHBOARD_REPORTS,
  RESUME_REVIEW_REPORTS,
  LANDSCAPE_REPORTS
}

const CANDIDATES = {
  LIST_CANDIDATES,
  CANDIDATE_PROFILE,
  CREATE_CANDIDATE,
  CANDIDATE_TAXONOMY,
  CANDIDATE_NOTES,
  CANDIDATE_WIDGETS,
  CANDIDATE_MEETINGS,
  CANDIDATE_DOCUMENTS,
  CANDIDATE_TAGGINGS,
  CANDIDATE_VACANCIES,
  ATTACH_TO_VACANCY,
  SHOW_VACANCY_PROFILE,
  CREATE_GROUP,
  CREATE_GROUP_LIST,
  ADD_CANDIDATE_EXPERIENCE,
  DELETE_CANDIDATE_EXPERIENCE,
  EDIT_CANDIDATE_EXPERIENCE,
  EDIT_CANDIDATE_EDUCATION,
  ADD_CANDIDATE_EDUCATION,
  DELETE_CANDIDATE_EDUCATION,
  EDIT_CANDIDATE,
};
const ADD_TEAM_MEMBER = "/add-team-member";
const MASTER_ROUTES = { MASTER };
const TEAM_MEMBER_PROFILE = "/users/:id";
const TEAM_LISTING_ROUTES = {
  TEAM_LISTING,
};
const COUNTIES_ROUTES = { COUNTIES };
const ADD_TEAM_MEMBER_ROUTE = { ADD_TEAM_MEMBER };
const TEAM_MEMBER_PROFILE_ROUTE = { TEAM_MEMBER_PROFILE };
const CREATE_VACANCY = "/vacancies";
const EDIT_VACANCY = "/vacancies/:id";
const STATUSES = "/status_priorities";
const LIST_VACANCY = "/vacancies"
const VACANCY_PROFILE = "/vacancies/:id"
const ATTACHED_CANDIDATES_FOR_VACANCY = "vacancies/:id/candidates/attached"
const CANDIDATES_PROCEDURE_STATUS_FOR_VACANCY = "vacancies/:id/candidates/stages/info"
const UPDATE_CANDIDATES_PROCEDURE_STATUS_FOR_VACANCY = "vacancies/:id/candidates/stages/:stageId"
const RECOMMENDED_CANDIDATES_FOR_VACANCY = "vacancies/:id/candidates/recommended"
const BULK_VACANCY_STATUS_UPDATE = "vacancies/status/bulk"
const VACANCY_TAXONOMY = "vacancies/:id/info"
const VACANCY_INVOICE = "/invoices"
const ADD_VACANCY_REVENUE = "/vacancies/:id/revenue"
const VACANCY = {
  CREATE_VACANCY,
  EDIT_VACANCY,
  STATUSES,
  LIST_VACANCY,
  VACANCY_PROFILE,
  ATTACHED_CANDIDATES_FOR_VACANCY,
  CANDIDATES_PROCEDURE_STATUS_FOR_VACANCY,
  UPDATE_CANDIDATES_PROCEDURE_STATUS_FOR_VACANCY,
  RECOMMENDED_CANDIDATES_FOR_VACANCY,
  BULK_VACANCY_STATUS_UPDATE,
  VACANCY_TAXONOMY,
  VACANCY_INVOICE,
  ADD_VACANCY_REVENUE,
};

const LIST_ACCOUNT = "/accounts";
const ACCOUNTS_PROFILE = "accounts/:id/detail";
const ACCOUNTS_TAXONOMY = "accounts/:id/info";
const ACCOUNT_MEETINGS = "/accounts/:id/meetings";
const ACCOUNT_DOCUMENTS = "/accounts/:id/documents";
const ACCOUNT_TERMS = "/accounts/:id/terms";
const ACCOUNT_VACANCIES = "/accounts/:id/vacancies";
const ACCOUNT_WIDGETS = "/accounts/:id/widgets";
const CREATE_ACCOUNT = "/accounts";
const ADD_SUBTEAM = "/accounts/:id/subTeam"
const ADD_ENTITY = "/accounts/:id/entity"
const ADD_OFFICE = "/accounts/:id/unit"
const ADD_TERMS = "/accounts/:id/term"


const ACCOUNTS = {
  LIST_ACCOUNT,
  ACCOUNTS_PROFILE,
  ACCOUNTS_TAXONOMY,
  ACCOUNT_MEETINGS,
  ACCOUNT_DOCUMENTS,
  ACCOUNT_TERMS,
  ACCOUNT_VACANCIES,
  ACCOUNT_WIDGETS,
  CREATE_ACCOUNT,
  ADD_SUBTEAM,
  ADD_ENTITY,
  ADD_OFFICE,
  ADD_TERMS,
};

const VACANCY_STATISTICS = "/vacancies/statistics";
const VACANCIES_LIST = "/vacancies/dashboard";
const SIGNIN = '/users/sign-in'
const SIGNOUT = '/users/sign-out'
const AUTH = {
  SIGNIN,
  SIGNOUT
}
const DASHBOARD = {
  VACANCY_STATISTICS,
  VACANCIES_LIST,
};
const MASTER_LEVEL_ROUTES = {
  DESIGNATIONS: {
    LISTING: "/designations",
  },

  TEAMS: {
    ROLES: {
      LISTING: "/roles",
    },
    TEAM_DESIGNATION: {
      LISTING: "/designations",
    },
    TEAMS: {
      LISTING: "/teams",
    },
    SUB_TEAM: {
      LISTING: "/teams",
    },
  },

  RESUME: {
    RESUME_TYPE: {
      LISTING: "/masterTypes",
    },
    EDUCATION_LEVEL: {
      LISTING: "/educationLevels",
    },
    EDUCATION_DEGREE: {
      LISTING: "/education-degrees",
    },
    EDUCATION_SPECIALIZATION: {
      LISTING: "/masters",
    },
    EDUCATION_INSTITUTE: {
      LISTING: "/institutes",
    },
  },

  COMPANY_MEETINGS_DOCUMENTS: {
    COMPANY_TYPE: {
      LISTING: "/documents",
    },
    MEETING_TYPE: {
      LISTING: "/documents",
    },
    DOCUMENT_TYPE: {
      LISTING: "/documents",
    },
  },
  PREFERENCE: {
    LEVEL: {
      LISTING: "/masterLevels",
    },
    FUNCTION_GROUP: {
      LISTING: "/masters",
    },
    FUNCTION: {
      LISTING: "/functions",
    },
    SUB_FUNCTION: {
      LISTING: "/functions",
    },
    SUB_SUB_FUNCTION: {
      LISTING: "/functions",
    },
    PRODUCT: {
      LISTING: "/products",
    },
    PRACTICE_AREA: {
      LISTING: "/practiceAreas",
    },
    SUB_PRACTICE: {
      LISTING: "/practiceAreas",
    },
    SEGMENT: {
      LISTING: "/segments",
    },
    VERTICAL: {
      LISTING: "/verticals",
    },
  },
  GLOBAL: {
    GENDER: {
      LISTING: "/masters",
    },
  },
  SITES: {
    LISTING: "/sites",
  },
  CURRENCY: {
    LISTING: "/currencies",
  },
  LOCATION: {
    COUNTIES: {
      LISTING: "/countries",
    },
    STATES: {
      LISTING: "/states",
    },
    CITIES: {
      LISTING: "/cities",
    },
    REGIONS: {
      LISTING: "/masters",
    },
  },
  VACANCY: {
    STATGE: {
      LISTING: "/master-status",
    },
    VACANCY_TYPE: {
      LISTING: "/masters",
    },
    VACANCY_STATUS: {
      LISTING: "/master-status",
    },
    DEPARTMENTS: {
      LISTING: "/departments",
    },
    PROCEDURE_TYPE: {
      LISTING: "/master-stages",
    },
    PROCEDURE_GROUP: {
      LISTING: "/masters",
    },
    INTERVIEW_TYPE: {
      LISTING: "/masters",
    },
    TRACKER_TYPE: {
      LISTING: "/masters",
    },
    REMINDER_TYPE: {
      LISTING: "/masters",
    },
    RM_STATUS: {
      LISTING: "/master-status",
    },
    SECTOR: {
      LISTING: "/sectors",
    },
    INDUSTRY: {
      LISTING: "/industries",
    },
  },
};

const LIST_CLIENT_PERSON = "/client_persons";
const CREATE_CLIENT_PERSON = "/client_persons";
const CLIENT_PERSON_PROFILE = "/client_persons/:id/detail";
const CLIENT_PERSON_NOTES = "/client_persons/:id/notes";
const CLIENT_PERSON_MEETINGS = "/client_persons/:id/meetings";

const CLIENT_PERSON = {
  LIST_CLIENT_PERSON,
  CREATE_CLIENT_PERSON,
  CLIENT_PERSON_PROFILE,
  CLIENT_PERSON_NOTES,
  CLIENT_PERSON_MEETINGS,
};

const MEETINGS = {
  LISTING: "/meetings",
};

const INVOICE_LISTING = "/invoices";
const INVOICE_PROFILE = "/invoices/:id/info";
const RECEIVE_PAYMENT = "/payments";
const INVOICE = {
  INVOICE_LISTING,
  INVOICE_PROFILE,
  RECEIVE_PAYMENT,
};

const TARGET_LISTING ="/targets";
const TARGET = {
  TARGET_LISTING,
}

const ROUTES = {
  CANDIDATES,
  ACCOUNTS,
  COUNTIES_ROUTES,
  MASTER_LEVEL_ROUTES,
  TEAM_MEMBER_PROFILE_ROUTE,
  ADD_TEAM_MEMBER_ROUTE,
  MASTER_ROUTES,
  VACANCY,
  CLIENT_PERSON,
  MEETINGS,
  DASHBOARD,
  INVOICE,
  REPORTS,
  AUTH,
  TARGET,
};
export {
  ROUTES,
  ACCOUNTS,
  COUNTIES_ROUTES,
  MASTER_LEVEL_ROUTES,
  TEAM_LISTING_ROUTES,
  CANDIDATES,
  TEAM_MEMBER_PROFILE_ROUTE,
  ADD_TEAM_MEMBER_ROUTE,
  MASTER_ROUTES,
  VACANCY,
  ACCOUNTS_PROFILE,
  DASHBOARD,
  MEETINGS,
  REPORTS,
  AUTH,
};
