const state = () => ({
  selectedVacancyIds: [],
});

const mutations = {
  setSelectedVacancyIds(state, selectedVacancies) {
    state.selectedVacancyIds.splice(0);
    Object.assign(state.selectedVacancyIds, selectedVacancies);
  },
  
}

const actions = {
  updateSelectedVacancies({
    commit
  }, selectedVacancies) {
    commit("setSelectedVacancyIds", selectedVacancies);
  },
  
};

const getters = {
  getSelectedVacancyIds: (state) => {
    return state.selectedVacancyIds;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};