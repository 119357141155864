import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index.js";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { ROUTES } from './constants/routes';
import "@vueup/vue-quill/dist/vue-quill.snow.css";
// import {useRoute} from 'vue-router';
import store from './store'

const app = createApp(App);
app.config.productionTip = false;

app.use(router);
app.use(store);
app.use(Antd);
app.mount('#app');

app.config.globalProperties.$routes = ROUTES;
// app.config.globalProperties.$route = useRoute();
// app.config.globalProperties.$roles = ROLES;
app.config.globalProperties.$path = window.location.pathname;

// Reamining Things OR Need To DO:-
// 1. Auth based Route Configuration
// 2. Constants 
// 3. Role based navigation
// 4. Define array of object for Nav Menus based on roles, which contains image, constant of path as Key and menu name
// 5. Globalize all fonts and add into theme
// 6. Implement Theme structure properly.
// 7. fix up naming for theme.scss