import { LiveAxios, MockAxios } from './axios';
import ROUTES from './router';

export default {
  candidatesListing: async (params) => {
    const route = ROUTES.CANDIDATE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceGroupSubGroupId(
          params.id,
          params.groups,
          params.subGroups,
          route.URL
        ),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },
  candidateCreate: async (params) => {
    const route = ROUTES.CANDIDATE.CREATE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }

    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  candidateProfile: async (params) => {
    const route = ROUTES.CANDIDATE.PROFILE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  candidateTaxonomy: async (params) => {
    const route = ROUTES.CANDIDATE.TAXONOMY;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  candidateNotes: async (params) => {
    const route = ROUTES.CANDIDATE.NOTES;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  candidateWidgets: async (params) => {
    const route = ROUTES.CANDIDATE.WIDGETS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  candidateMeetings: async (params) => {
    const route = ROUTES.CANDIDATE.MEETINGS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  candidateDocuments: async (params) => {
    const route = ROUTES.CANDIDATE.DOCUMENTS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  candidateTaggings: async (params) => {
    const route = ROUTES.CANDIDATE.TAGGINGS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  candidateVacancies: async (params) => {
    const route = ROUTES.CANDIDATE.VACANCIES;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },

  userListing: async (params) => {
    const route = ROUTES.USERS.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },
  currencyListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.CURRENCY.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },
  genderListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.GENDERS.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  countryListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.COUTRIES.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  cityListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.CITIES.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  stateListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.STATES.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  rolesListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.TEAMS.ROLE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  sitesListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.SITES.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  regionListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.REGIONS.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  teamListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.TEAMS.TEAM.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  resumeListing: async (params) => {
    const route = ROUTES.RESUME.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  eduLevelListing: async (params) => {
    const route = ROUTES.EDU_LEVEL.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  eduDegreesListing: async (params) => {
    const route = ROUTES.EDU_DEGREE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  eduInstituteListing: async (params) => {
    const route = ROUTES.EDU_INSTITUTE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  /* master Preference css call */
  getlevelDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.PREFERENCE.LEVEL.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },

  getFunctionGroupDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.PREFERENCE.FUNCTION_GROUP.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },

  getFunctionDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.PREFERENCE.FUNCTION.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getSubFunctionDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.PREFERENCE.SUB_FUNCTION.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getSubSubFunctionDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.PREFERENCE.SUB_SUB_FUNCTION.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getProductMasterDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.PREFERENCE.PRODUCT.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getVerticalMasterDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.PREFERENCE.VERTICAL.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getPracticeAreaDetail: async (params) => {
    const route =
      ROUTES.MASTER_ROUTES.PREFERENCE.VERTICAL.PRACTICE_AREA.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getSubPracticeAreaDetail: async (params) => {
    const route =
      ROUTES.MASTER_ROUTES.PREFERENCE.VERTICAL.SUB_PRACTICE_AREA.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getSegmentDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.PREFERENCE.SEGMENT.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },

  /* master Vacancy css call */
  getStageDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.STAGE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },

  getVacancyTypeDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.VACANCY_TYPE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },

  getVacancyStatusDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.VACANCY_STATUS.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getDepartmentsDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.DEPARTMENTS.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getProcedureTypeDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.PROCEDURE_TYPE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getProcedureGroupDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.PROCEDURE_GROUP.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getInterViewTypeDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.INTERVIEW_TYPE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getTrackerTypeDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.TRACKER_TYPE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getReminderTypeDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.REMINDER_TYPE.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getRmStatusDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.RM_STATUS.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getSectorDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.SECTOR.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },
  getIndustryDetail: async (params) => {
    const route = ROUTES.MASTER_ROUTES.VACANCY.INDUSTRY.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    } else {
      return MockAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
  },

  createTeamMember: async (params) => {
    const route = ROUTES.ADD_TEAM_MEMBER.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      // params: params,
      data: params,
    });
  },
  getTeamMemberProfile: async (params) => {
    const route = ROUTES.TEAM_MEMBER.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  designationListing: async (params) => {
    const route = ROUTES.MASTER_ROUTES.DESIGNATION.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },
  getAccountListing: async (params) => {
    const route = ROUTES.ACCOUNTS.LISTING;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },

  getAccountTaxonomy: async (params) => {
    const route = ROUTES.ACCOUNTS_TAXONOMY.TAXONOMY;

    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  createVacancy: async (params) => {
    const route = ROUTES.CREATE_VACANCY.CREATE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        data: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      data: params,
    });
  },
  getVacancyStatuses: async (params) => {
    const route = ROUTES.CREATE_VACANCY.STATUS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },
  getAccountProfile: async (params) => {
    const route = ROUTES.ACCOUNTS_PROFILE.PROFILE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getAccountMeetings: async (params) => {
    const route = ROUTES.ACCOUNTS_MEETINGS.MEETINGS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getAccountDocumnets: async (params) => {
    const route = ROUTES.ACCOUNTS_DOCUMENTS.DOCUMENTS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getAccountTerms: async (params) => {
    const route = ROUTES.ACCOUNTS_TERMS.TERMS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getAccountVacancies: async (params) => {
    const route = ROUTES.ACCOUNTS_VACANCIES.VACANCIES;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params.id, route.URL),
      params: params,
    });
  },
  getAccountWidgets: async (params) => {
    const route = ROUTES.ACCOUNTS_WIDGETS.WIDGETS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getClientPersonListing: async (params) => {
    const route = ROUTES.CLIENT_PERSON.LISTING;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  createClientPerson: async (params) => {
    const route = ROUTES.CLIENT_PERSON.CREATE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }

    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params,
    });
  },
  getVacancyListing: async (params) => {
    const route = ROUTES.CREATE_VACANCY.LISTING;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getVacancyProfile: async (params) => {
    const route = ROUTES.CREATE_VACANCY.PROFILE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },

  attachToVacancy: async (params) => {
    const route = ROUTES.CANDIDATE.ATTACH_TO_VACANCIES;
    if (!route.IS_MOCK) {
      console.log('params.id :>> ', params.id);
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        data: params.data,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },

  getCandidatesAttachedToVacancy: async (params) => {
    const route = ROUTES.CREATE_VACANCY.ATTACHED_CANDIDATES;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },

  getCandidateShowVacancyProfile: async (params) => {
    const route = ROUTES.CANDIDATE.CANDIDATE_SHOW_VACANCY_PROFILE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },

  getVacancyStatistics: async (params) => {
    const route = ROUTES.DASHBOARD.LIST_VACANCY_STATISTICS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },

  candidateCreateGroup: async (params) => {
    const route = ROUTES.CANDIDATE.CANDIDATE_CREATE_GROUP;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },

  getCandidateGroup: async (params) => {
    const route = ROUTES.CANDIDATE.CANDIDATE_CREATE_GROUP_LIST;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },

  UpdateCandidatesProcedureStatusforVacancy: async (params) => {
    const route = ROUTES.CREATE_VACANCY.UPDATE_CANDIDATES_PROCEDURE_STATUS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceStageId(params.id, params.stageId, route.URL),
        data: params.data,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceStageId(params.id, params.stageId, route.URL),
      data: params.data,
    });
  },

  getCandidatesProcedureStatusforVacancy: async (params) => {
    const route = ROUTES.CREATE_VACANCY.CANDIDATES_PROCEDURE_STATUS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },

  RecommendedCandidatesforVacancy: async (params) => {
    const route = ROUTES.CREATE_VACANCY.RECOMMENDED_CANDIDATES;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },

  getVacanciesListDashboard: async (params) => {
    const route = ROUTES.DASHBOARD.LIST_VACANCIES_DASHBOARD;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getMeetings: async (params) => {
    const route = ROUTES.MEETINGS.LISTING;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params,
    });
  },
  getClientPersonDetails: async (params) => {
    const route = ROUTES.CLIENT_PERSON.PROFILE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params,
    });
  },
  vacancyBulkUpdates: async (params) => {
    const route = ROUTES.CREATE_VACANCY.VACANCY_BULK_UPDATE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params,
    });
  },
  vacancyTaxonomy: async (params) => {
    const route = ROUTES.CREATE_VACANCY.TAXONOMY;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getClientPersonNotes: async (params) => {
    const route = ROUTES.CLIENT_PERSON.NOTES;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getClientPersonMeetings: async (params) => {
    const route = ROUTES.CLIENT_PERSON.MEETINGS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  addCandidateExperience: async (params) => {
    const route = ROUTES.CANDIDATE.CANDIDATE_EXPERIENCE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        data: params.data,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params.data,
    });
  },

  deleteCandidateExperience: async (params) => {
    const route = ROUTES.CANDIDATE.DELETE_CANDIDATE_EXPERIENCE;
    if (!route.IS_MOCK) {
      console.log('url ----', params);

      return LiveAxios({
        method: route.METHOD,
        url: replaceExperienceId(params.id, params.experienceId, route.URL),
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceExperienceId(params, route.URL),
      params: params,
    });
  },
  addCandidateEducation: async (params) => {
    const route = ROUTES.CANDIDATE.CANDIDATE_EDUCATION;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        data: params.data,
      });
    }
    console.log('url', route.URL);
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params.data,
    });
  },

  deleteCandidateEducation: async (params) => {
    const route = ROUTES.CANDIDATE.DELETE_CANDIDATE_EDUCTION;
    if (!route.IS_MOCK) {
      console.log('url ----', params);

      return LiveAxios({
        method: route.METHOD,
        url: replaceEducationId(params.id, params.qualificationId, route.URL),
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceEducationId(params, route.URL),
      params: params,
    });
  },

  updateCandidateEducation: async (params) => {
    const route = ROUTES.CANDIDATE.EDIT_CANDIDATE_EDUCATION;
    if (!route.IS_MOCK) {
      console.log('url ----', params);

      return LiveAxios({
        method: route.METHOD,
        url: replaceEducationId(params.id, params.qualificationId, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceEducationId(params, route.URL),
      params: params,
    });
  },
  editCandidate: async (params) => {
    const route = ROUTES.CANDIDATE.EDIT_CANDIDATE;
    if (!route.IS_MOCK) {
      console.log('route.url : >> ', route.URL);
      console.log('url ----', params);

      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params.id, route.URL),
      data: params.data,
    });
  },
  updateCandidateExperience: async (params) => {
    const route = ROUTES.CANDIDATE.EDIT_CANDIDATE_EXPERIENCE;
    if (!route.IS_MOCK) {
      console.log('url ----', params);

      return LiveAxios({
        method: route.METHOD,
        url: replaceExperienceId(params.id, params.experienceId, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceExperienceId(params, route.URL),
      params: params,
    });
  },
  createAccount: async (params) => {
    const route = ROUTES.ACCOUNTS.CREATE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }

    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params,
    });
  },
  createVacancyInvoice: async (params) => {
    const route = ROUTES.CREATE_VACANCY.INVOICE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }

    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params,
    });
  },
  getInvoiceListing: async (params) => {
    const route = ROUTES.INVOICE.LISTING;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  getInvoice: async (params) => {
    const route = ROUTES.INVOICE.PROFILE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  dashboardReports: async (params) => {
    const route = ROUTES.REPORTS.DASHBOARD;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  resumeReviewReports: async (params) => {
    const route = ROUTES.REPORTS.RESUME_REVIEW;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  postLandscapeReports: async (params) => {
    const route = ROUTES.REPORTS.LANDSCAPE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  editVacancy: async (params) => {
    const route = ROUTES.CREATE_VACANCY.EDIT;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params.id, route.URL),
      data: params.data,
    });
  },
  receivePayments: async (params) => {
    const route = ROUTES.INVOICE.PAYMENT;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      params: params,
    });
  },
  addVacancyRevenueDetails: async (params) => {
    const route = ROUTES.CREATE_VACANCY.ADD_REVENUE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.id, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params.id, route.URL),
      data: params.data,
    });
  },
  signIn: async (params) => {
    const route = ROUTES.SIGNIN;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }

    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  signOut: async (params) => {
    const route = ROUTES.SIGNOUT;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params, route.URL),
        data: params,
      });
    }

    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
    });
  },
  addSubTeam: async (params) => {
    const route = ROUTES.ADD_SUBTEAM;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.clientId, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params.data,
    });
  },
  addEntity: async (params) => {
    const route = ROUTES.ADD_ENTITY;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.clientId, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params.data,
    });
  },
  addOffice: async (params) => {
    const route = ROUTES.ADD_OFFICE;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.clientId, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params.data,
    });
  },
  addTerms: async (params) => {
    const route = ROUTES.ADD_TERMS;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: replaceId(params.clientId, route.URL),
        data: params.data,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: replaceId(params, route.URL),
      data: params.data,
    });
  },
  fetchTargets: async (params) => {
    const route = ROUTES.TRAGETS.LISTING;
    if (!route.IS_MOCK) {
      return LiveAxios({
        method: route.METHOD,
        url: route.URL,
        params: params,
      });
    }
    return MockAxios({
      method: route.METHOD,
      url: route.URL,
      params: params,
    });
  },
};

function replaceId(id, url) {
  let newURL = url.replace(/:id/g, id);
  console.log(newURL, 'new url');
  return newURL;
}

function replaceStageId(id, stageId, url) {
  let newURL = url.replace(/:id/g, id);
  let updatedUrl = newURL;
  newURL = updatedUrl.replace(/:stageId/g, stageId);
  return newURL;
}

function replaceExperienceId(id, experienceId, url) {
  let newURL = url.replace(/:id/g, id);
  let updatedUrl = newURL;
  newURL = updatedUrl.replace(/:experienceId/g, experienceId);
  return newURL;
}

function replaceEducationId(id, qualificationId, url) {
  let newURL = url.replace(/:id/g, id);
  console.log('qualid :', qualificationId);
  let updatedUrl = newURL;
  newURL = updatedUrl.replace(/:qualificationId/g, qualificationId);
  return newURL;
}

function replaceGroupSubGroupId(id, groups, subGroups, url) {
  let newURL = url.replace(/:id/g, id);
  let updatedUrl = newURL;
  newURL =
    updatedUrl.replace(/:groups/g, groups) ||
    updatedUrl.replace(/:subGroups/g, subGroups);
  console.log('newURL :>> ', newURL);
  return newURL;
}
