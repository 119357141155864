const state = () => ({
    selectedclientIds: [],
  });
  
  const mutations = {
    setSelectedclientIds(state, selectedclients) {
      state.selectedclientIds.splice(0);
      Object.assign(state.selectedclientIds, selectedclients);
    },
    
  }
  
  const actions = {
    updateSelectedclients({
      commit
    }, selectedclients) {
        console.log("id",selectedclients)
      commit("setSelectedclientIds", selectedclients);
    },
    
  };
  
  const getters = {
    getSelectedclientIds: (state) => {
        console.log(state.selectedclientIds,'get selected id')
      return state.selectedclientIds;
    },
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };