<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <a-layout-header class="app-layout-header" v-if="isUserLoggedIn">
      <Header :headerTitle="headerTitle" />
    </a-layout-header> 
    <a-layout>
      <a-layout-sider
        v-if="isUserLoggedIn"
        class="app-layout-slider"
        v-model="collapsed"
        :collapsible="false"
        :style="{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          width: '80px !important',
          maxwidth: '80px',
        }"
      >
        <LeftNavigation :collapsed="collapsed" @onMenuClick="onClickNavItems" />
      </a-layout-sider>
      <a-layout-content :class="!isUserLoggedIn ? '' : 'container'">
        <router-view
          :class="
            !isUserLoggedIn
              ? ''
              : !isWidgetOpen
              ? 'content-container-full'
              : 'content-container-partial'
          "
        />
      </a-layout-content>
      <a-back-top />
    </a-layout>
  </a-layout>
</template>
<script>
import LeftNavigation from "@/features/common/components/leftNavigation.vue";
import Header from "@/features/common/components/header.vue";
export default {
  name: "App",
  components: {
    LeftNavigation,
    Header,
  },
  data() {
    return {
      collapsed: true,
      isUserLoggedIn: false,
      isWidgetOpen: false,
      headerTitle: "DASHBOARD",
    };
  },
  watch: {
    $route() {
      if (window.location.pathname === "/") {
        this.isUserLoggedIn = false;
      } else {
        this.isUserLoggedIn = true;
      }
    },
  },
  methods: {
    isWidgetOpenCheck(param) {
      this.isWidgetOpen = param.isWidgetOpen;
    },
    onClickNavItems(value) {
      this.headerTitle = value;
    },
  },
};
</script>

<style lang="scss">
@import "./theme.scss";

// Poppins
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "PoppinsThin";
  src: local("PoppinsThin"),
    url("./fonts/Poppins/Poppins-Thin.ttf") format("truetype"),
    url("./fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url("./fonts/Poppins/Poppins-SemiBold.ttf") format("truetype"),
    url("./fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Poppins Medium";
  src: local("Poppins Medium"),
    url("./fonts/Poppins/Poppins-Medium.ttf") format("truetype"),
    url("./fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "PoppinsBlack";
  src: local("PoppinsBlack"),
    url("./fonts/Poppins/Poppins-Black.ttf") format("truetype"),
    url("./fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype"),
    url("./fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "PoppinsExtraBold";
  src: local("PoppinsExtraBold"),
    url("./fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype"),
    url("./fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "PoppinsExtraLight";
  src: local("PoppinsExtraLight"),
    url("./fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype"),
    url("./fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url("./fonts/Poppins/Poppins-Light.ttf") format("truetype"),
    url("./fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
}

// Pangram
@font-face {
  font-family: "Pangram Medium";
  src: local("Pangram Medium"),
    url("./fonts/Pangram/Pangram-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "PangramExtraBold";
  src: local("PangramExtraBold"),
    url("./fonts/Pangram/Pangram-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.app-layout-slider.ant-layout-sider {
  background: $panels;
  max-width: 60px !important;
  min-width: 60px !important;
  background: #4c5155 !important;
  overflow: hidden !important;
  margin-top: 80px !important;
}

.container {
  display: flex;
  justify-content: center;
}

.content-container-full {
  width: 100%;
  margin: 80px 0px 0px 80px !important;
  height: auto;
}

.content-container-partial {
  width: calc(100% - 50px);
  padding: 0 25px 25px 25px;
  margin: 100px 0px 0px 80px !important;
  height: auto;
}

.right-widget-panel-open {
  min-width: 270px !important;
  max-width: 270px !important;
  background: transparent !important;
  flex: 0 0 270px !important;
  width: 270px !important;

  .ant-layout-sider-children {
    background: $shade4;
  }
}

.right-widget-panel-close {
  background: transparent !important;
  flex: 0 !important;
  width: 0 !important;
  min-width: 0 !important;
  max-width: 0 !important;
}
.app-layout-header {
  padding: 0px !important;
  border-bottom: 1px solid #ffe3a4 !important;
  z-index: 999 !important;
}
</style>
