<template>
  <a-layout-header
    class="header-root"
    style="height: 80px; border-bottom: 1px solid #ffe3a4"
  >
    <div class="header-native-world-logo-wrapper">
      <span class="header-native-world-logo">N</span>
    </div>
    <div class="header-left">
      <span class="header-left-text">{{ headerTitle }}</span>
    </div>
    <div class="header-right">
      <a-button class="target-color-btn" style="margin: 0px 20px;background: #6866dc;
      color: white;
      border-color: #6866dc;" v-if="headerTitle == 'TARGET'" >Create <DownOutlined /></a-button>
      <div class="divider" v-if="headerTitle == 'TARGET'"></div>
      &nbsp;&nbsp;&nbsp;
      <img src="../../../assets/search.png" />
      <img src="../../../assets/notify.png" style="margin: 0px 40px" />
      <div class="divider"></div>
      <img src="../../../assets/setting.png" style="margin: 0px 40px" />
      <div class="divider"></div>
      <div class="name-short-main">
        <span class="name-short">{{ user ? user.slice(0,1) :'Us' }}{{ user ? user.split(" ")[2].slice(0,1) : 'er' }}</span>
      </div>
      <div class="header-logout">
        <div class="header-logout-label">
          <span class="logout-label-header">{{ user }}</span>
          <span class="logout-label-text">Admin</span>
        </div>

        <a-popover v-model:visible="visible" trigger="hover">
          <template #content>
            <div style="width: 225px !important">
              <div
                style="cursor: pointer; padding-bottom: 5px"
                @click="onClickMyProfile"
              >
                <UserOutlined style="margin-right: 8px" />
                <span
                  style="
                    font-family: 'Poppins Medium';
                    font-size: 13px;
                    color: #000000;
                  "
                  >My Profile
                </span>
              </div>
              <div
                @click="onClickLogout"
                style="cursor: pointer; padding-bottom: 5px"
              >
                <LogoutOutlined style="margin-right: 8px" />
                <span
                  style="
                    font-family: 'Poppins Medium';
                    font-size: 13px;
                    color: #000000;
                  "
                  >Logout
                </span>
              </div>
            </div>
          </template>
          <DownOutlined style="font-size: 15px" />
        </a-popover>
      </div>
    </div>
  </a-layout-header>
</template>



<script>
import { ref } from "vue";
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { onMounted, reactive, computed } from "vue";
import Services from "../../../services/apis";
export default {
  components: {
    DownOutlined,
    UserOutlined,
    LogoutOutlined,
  },
  props: ["headerTitle"],

  setup(props) {
    const router = useRouter();
    const visible = ref(false);
    const onClickLogout = () => {
      Services.signOut()
        .then((response) => {
          router.push("/");
          visible.value = false;
          localStorage.removeItem("Token");
          localStorage.removeItem("User");
          message.success(`Logged out successfully`);
        })
        .catch((err) => {
          message.error(`Failed`);
        });
    };
    const user = localStorage.getItem("User");
    const token = localStorage.getItem("Token");
    const onClickMyProfile = () => {
      visible.value = false;
    };
    const validation = () => {
      if (!token) {
        message.error(`Login Required!`);
        router.push("/");
      }
    };
    onMounted(() => {
      validation();
    });
    return {
      visible,
      onClickLogout,
      onClickMyProfile,
      user,
    };
  },
};
</script>

<style lang="scss">
@import "../styles/header.scss";
</style>
