import Services from "@/services/apis";
import { message } from "ant-design-vue";

const state = () => ({
  selectedCandidatesForVacancyStage: [],
  selectedCandidates: "",
  candidateId: "",
  procedureStageId: "",
  vacancyId: "",
  groupId: "",
  formState: "",
  formData: {},
  experienceId: "",
  qualificationId:"",
  subGroupId: "",
});

const getDefaultState = () => {
  return {
    groupId: "",
    subGroupId: ""
  }
}

const mutations = {

  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
    console.log('state -----:>> ', state);
  },

  getSelectedCandidateId(state, stateCandidateId) {
    state.candidateId = stateCandidateId;
  },

  addSelectedCandidates(state, selectedCandidatesObj) {
    state.selectedCandidates = selectedCandidatesObj;
  },

  getVacancyProcedureStageData(state, procedureStageObj) {
    state.procedureStageId = procedureStageObj;
  },

  getVacancyIdData(state, vacancyIdObj) {
    state.vacancyId = vacancyIdObj;
  },
  getSelectedGroupId(state, stateGroupId) {
    state.groupId = stateGroupId;
  },
  getFormState(state, stateFormName) {
    state.formState = stateFormName;
  },
  getFormData(state, stateFormData) {
    state.formData = stateFormData;
  },
  getCandidateExperienceId(state, stateExperienceId) {
    state.experienceId = stateExperienceId;
    console.log('state.experienceId :>> ', state.experienceId);
  },
  getCandidateEducationId(state, stateQualificationId) {
    state.qualificationId = stateQualificationId;
    console.log('state.qualificationId :>> ', state.qualificationId);
  },

  getsubGroupId(state , stateSubGroupId){
    state.subGroupId = stateSubGroupId;
  }
};

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE')
  },
  getcandidateIds({ state, commit }, candidateId) {
    commit("getSelectedCandidateId", candidateId);
  },
  candidatesListingSelectedCandidates({ state, commit }, selectedCandidates) {
    commit("addSelectedCandidates", selectedCandidates);
  },

  candidateProfileSelectedProcedureStage({ state, commit }, procedureStageId) {
    commit("getVacancyProcedureStageData", procedureStageId);
  },

  candidateProfileSelectedVacancy({ state, commit }, vacancyId) {
    commit("getVacancyIdData", vacancyId);
  },

  getGroupIds({ state, commit }, groupId) {
    commit("getSelectedGroupId", groupId);
  },

  attachCandidateToVacancyInProfile({ state }, profile = true) {
    let id = state.vacancyId;

    let arrayObjct = [];
    if (profile) {
      let tempObject = {
        candidateId: state.candidateId,
        procedureStageId: state.procedureStageId,
      };
      arrayObjct.push(tempObject);
    } else {
      arrayObjct = state.selectedCandidates.map((x) => {
        let tempObject = {
          candidateId: x.id,
          procedureStageId: state.procedureStageId,
        };
        return tempObject;
      });
    }
    let payload = {
      id: id,
      data: arrayObjct,
    };
    Services.attachToVacancy(payload)
      .then((response) => {
        const { data } = response;

        arrayObjct = [];

        message.success("Candidates are successfully attached to a vacancy");
        state.selectedCandidates = "";
      })
      .catch((e) => {
        console.log("error :>> ", e);
      });
  },

  getProcedureStagesCandidatesProfile({ state }) {
    let id = state.selectedCandidates.map((x) => x.id);

    Services.getCandidateShowVacancyProfile(id)
      .then((response) => {
        const { data } = response;

        state.selectedCandidatesForVacancyStage = data;
      })
      .catch((e) => {
        console.log("error :>> ", e);
      });
  },

  getCandidateWorkDetailsFormState({ state, commit }, formState) {
    commit("getFormState", formState);
  },
  getCandidateEducationFormState({ state, commit }, formState) {
    commit("getFormState", formState);
  },
  

  getCandidateWorkDetailsFormData({ state, commit }, formData) {
    commit("getFormData", formData);
  },
  getCandidateEducationFormData({ state, commit }, formData) {
    commit("getFormData", formData);
  },

  getCandidateWorkExperienceId({ state, commit }, experienceId) {
    commit("getCandidateExperienceId", experienceId);
    console.log('experienceId ------Action :>> ', experienceId );
  },
  getCandidateEducationDetailId({ state, commit }, qualificationId) {
    commit("getCandidateEducationId", qualificationId);
    console.log('qualifyId ------Action :>> ', qualificationId );
  },
  getCandidateSubGroupId({state, commit } , subGroupId){
      commit("getsubGroupId" , subGroupId);
  }
};

const getters = {
  getVacancyProfileProcedureStatusData: (state, getters) => {
    return state.selectedCandidates;
  },

  getCandidateGroupId: (state, getters) => {
    console.log('state.groupId :>> ', state.groupId);
    return state.groupId;
  },

  getCandidateFormState: (state, getters) => {
    return state.formState;
  },

  getCandidateFormData: (state, getters) => {
    return state.formData;
  },

  getExperienceIdOfCandidate: (state, getters) => {
    console.log('state.experienceId :>> ', state.experienceId);
    return state.experienceId;
  },
  getEducationIdOfCandidate: (state, getters) => {
    console.log('state.eduId :>> ', state.qualificationId);
    return state.qualificationId;
  },
  getSubGroupIdOfCandidate : (state, getters ) => {
    console.log('state.subGroupId :>> ', state.subGroupId);
    return state.subGroupId
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};