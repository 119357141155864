import { createStore } from "vuex";
import workbenchStore from "./features/workbench/stores/workbenchStore";
import candidateStore from "./features/candidate/stores/candidateStore";
import vacancyStore from "./features/vacancy/stores/vacancyStore";
import clientStore from "./features/company/stores/clientStore";

export default createStore({
  modules: {
    workbenchStore,
    candidateStore,
    vacancyStore,
    clientStore

  },
});
