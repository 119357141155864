import axios from "axios";
import { MOCK_API_BASE_URL, API_BASE_URL } from "../../../constants";

const MockAxios = axios.create({
  baseURL: "http://stoplight.io/mocks/prodio-designworks/native-world/182542",
});

MockAxios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("Token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

MockAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let authTokens = localStorage.getItem("Token")
  ? localStorage.getItem("Token")
  : null;

const LiveAxios = axios.create({
  // baseURL: API_BASE_URL,
  baseURL: "https://erecvito-api-dev.brainvire.net/api/v1",
  headers: { Authorization: `Bearer ${authTokens?.access}` },
});

LiveAxios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("Token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

LiveAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { LiveAxios, MockAxios };
