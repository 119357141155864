import { createWebHistory, createRouter } from "vue-router";

// import About from "@/views/About.vue";

const routes = [
  {
    path: "/candidates",
    name: "List-Candidates",
    component: () =>
      import("../features/candidate/components/listCandidates.vue"),
  },
  {
    path: "/candidates/:id/detail",
    name: "Candidate Profile",
    component: () =>
      import("../features/candidate/components/candidateProfile.vue"),
  },
  // {
  //     path: "/master",
  //     name: "Master Table",
  //     component: () =>
  //         import ("../features/master/components/masterFeature/global/index.vue"),

  // },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => Home,
  // },
  {
    path: "/company/create-company",
    name: "Create Company",
    component: () => import("../features/company/components/CreateCompany.vue"),
  },

  // {

  //     path: "/dashboard",
  //     name: "Candidate dashboard",
  //     component: () =>
  //         import ("../features/candidate/components/dashboard.vue"),

  // },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => Home,
  // },
  {
    path: "/add-client-person",
    name: "Create Client Account",
    component: () =>
      import("../features/company/components/createClientPerson.vue"),
  },
  // {
  //     path: "/candidate-profile",
  //     name: "Candidate Profile",
  //     component: () => CandidateProfile,
  // },
  {
    path: "/",
    name: "Login",
    component: () => import("../features/auth/components/login.vue"),
  },
  {
    path: "/list-companies",
    name: "List Companies",
    component: () => import("../features/company/components/CompanyList.vue"),
  },
  {
    path: "/add-candidate",
    name: "Add Candidate",
    component: () =>
      import("../features/candidate/components/addCandidate.vue"),
  },
  {
    path: "/accounts/:id/detail",
    name: "Account Profile",
    component: () =>
      import("../features/company/components/accountProfile.vue"),
  },
  {
    path: "/company/edit-company",
    name: "Edit Company",
    component: () => import("../features/company/components/EditCompany.vue"),
  },
  {
    path: "/client_persons/:id/detail",
    name: "Client Person Profile",
    component: () =>
      import("../features/company/components/clientPersonProfile.vue"),
  },
  {
    path: "/vacancies/:id",
    name: "Vacancy Profile",
    component: () =>
      import("../features/workbench/components/vacancyProfile.vue"),
  },
  {
    path: "/vacancy/:id/attachCandidates",
    name: "Attach Candidates To Vacancy",
    component: () =>
      import("../features/workbench/components/attachCandidates.vue"),
  },
  {
    path: "/vacancy/vacancy-list",
    name: "Vacancy List",
    component: () => import("@/features/vacancy/components/VacancyList.vue"),
  },
  {
    path: "/candidates/:id/detail",
    name: "Candidate Profile",
    component: () =>
      import("../features/candidate/components/candidateProfile.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/features/dashboards/components/Dashboard.vue"),
  },
  {
    path: "/add-account",
    name: "Create Company",
    component: () => import("../features/company/components/CreateCompany.vue"),
  },
  {
    path: "/",
    name: "Login",
    component: () => import("../features/auth/components/login.vue"),
  },
  {
    path: "/list-companies",
    name: "List Companies",
    component: () => import("../features/company/components/CompanyList.vue"),
  },
  {
    path: "/add-candidate",
    name: "Add Candidate",
    component: () =>
      import("../features/candidate/components/addCandidate.vue"),
  },
  {
    path: "/company/edit-company",
    name: "Edit Company",
    component: () => import("../features/company/components/EditCompany.vue"),
  },
  {
    path: "/client-profile",
    name: "Client Profile",
    component: () => import("../features/client/components/clientProfile.vue"),
  },
  {
    path: "/vacancy/vacancy-list",
    name: "Vacancy List",
    component: () => import("@/features/vacancy/components/VacancyList.vue"),
  },

  {
    path: "/master",
    name: "Master",
    component: () => import("../features/master/components/masterMain.vue"),
  },
  {
    path: "/add-team-member",
    name: "Add Team Member",
    component: () => import("../features/users/components/addTeamMember.vue"),
  },
  {
    path: "/team-member-profile/:id",
    name: "Team Member Profile",
    component: () =>
      import("../features/users/components/teamMemberProfile.vue"),
  },
  {
    path: "/vacancy/create-new-vacancy",
    name: "Create New Vacancy",
    component: () =>
      import("../features/vacancy/components/CreateNewVacancy.vue"),
  },
  {
    path: "/company/create-company",
    name: "Create Account",
    component: () => import("../features/company/components/CreateCompany.vue"),
  },
  {
    path: "/",
    name: "Login",
    component: () => import("../features/auth/components/login.vue"),
  },
  {
    path: "/list-companies",
    name: "List Companies",
    component: () => import("../features/company/components/CompanyList.vue"),
  },
  {
    path: "/add-candidate",
    name: "Add Candidate",
    component: () =>
      import("../features/candidate/components/addCandidate.vue"),
  },
  {
    path: "/company/edit-company",
    name: "Edit Company",
    component: () => import("../features/company/components/EditCompany.vue"),
  },
  {
    path: "/client-profile",
    name: "Client Profile",
    component: () => import("../features/client/components/clientProfile.vue"),
  },
  {
    path: "/reports/listing",
    name: "Reports Listing",
    component: () => import("@/features/reports/components/reportsListing.vue"),
  },
  {
    path: "/meetings",
    name: "Meetings",
    component: () => import("../features/common/components/meettingList.vue"),
  },
  {
    path: "/edit-candidate/:id",
    name: "Edit Candidate",
    component: () =>
      import("../features/candidate/components/editCandidate.vue"),
  },
  {
    path: "/billing",
    name: "Billing & Invoices",
    component: () =>
      import("../features/billing/components/billingDashboard.vue"),
  },
  {
    path: "/edit-vacancy",
    name: "Edit Vacancy",
    component: () => import("../features/vacancy/components/editVacancy.vue"),
  },
  {
    path: "/target",
    name: "Target",
    component: () => import("../features/targets/components/listTargets.vue"),
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
