import {
  ROUTES,
  COUNTIES_ROUTES,
  TEAM_MEMBER,
  TEAM_LISTING_ROUTES,
  MASTER_LEVEL_ROUTES,
  TEAM_MEMBER_PROFILE_ROUTE,
  ADD_TEAM_MEMBER_ROUTE,
  CANDIDATES,
  VACANCY,
  MASTER_ROUTES,
  ACCOUNTS_PROFILE,
  MEETINGS,
} from "../../../constants/routes";

const ROUTER = {
  CREATE_VACANCY: {
    CREATE: {
      METHOD: "POST",
      URL: ROUTES.VACANCY.CREATE_VACANCY,
      IS_MOCK: false,
    },
    STATUS: {
      METHOD: "GET",
      URL: ROUTES.VACANCY.STATUSES,
      IS_MOCK: false,
    },
    LISTING: {
      METHOD: "GET",
      URL: ROUTES.VACANCY.LIST_VACANCY,
      IS_MOCK: false,
    },
    PROFILE: {
      METHOD: "GET",
      URL: ROUTES.VACANCY.VACANCY_PROFILE,
      IS_MOCK: false,
    },
    ATTACHED_CANDIDATES: {
      METHOD: "GET",
      URL: ROUTES.VACANCY.ATTACHED_CANDIDATES_FOR_VACANCY,
      IS_MOCK: false,
    },
    CANDIDATES_PROCEDURE_STATUS: {
      METHOD: "GET",
      URL: ROUTES.VACANCY.CANDIDATES_PROCEDURE_STATUS_FOR_VACANCY,
      IS_MOCK: false,
    },
    UPDATE_CANDIDATES_PROCEDURE_STATUS: {
      METHOD: "POST",
      URL: ROUTES.VACANCY.UPDATE_CANDIDATES_PROCEDURE_STATUS_FOR_VACANCY,
      IS_MOCK: false,
    },
    RECOMMENDED_CANDIDATES: {
      METHOD: "GET",
      URL: ROUTES.VACANCY.RECOMMENDED_CANDIDATES_FOR_VACANCY,
      IS_MOCK: false,
    },
    VACANCY_BULK_UPDATE: {
      METHOD: "POST",
      URL: ROUTES.VACANCY.BULK_VACANCY_STATUS_UPDATE,
      IS_MOCK: false,
    },
    TAXONOMY: {
      METHOD: "GET",
      URL: ROUTES.VACANCY.VACANCY_TAXONOMY,
      IS_MOCK: false,
    },
    INVOICE: {
      METHOD: "POST",
      URL: ROUTES.VACANCY.VACANCY_INVOICE,
      IS_MOCK: false,
    },
    EDIT: {
      METHOD: "POST",
      URL: ROUTES.VACANCY.EDIT_VACANCY,
      IS_MOCK: false,
    },
    ADD_REVENUE: {
      METHOD: "PATCH",
      URL: ROUTES.VACANCY.ADD_VACANCY_REVENUE,
      IS_MOCK: false,
    }
  },
  CANDIDATE: {
    CREATE: {
      METHOD: "POST",
      URL: ROUTES.CANDIDATES.CREATE_CANDIDATE,
      IS_MOCK: false,
    },
    LISTING: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.LIST_CANDIDATES,
      IS_MOCK: false,
    },
    PROFILE: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.CANDIDATE_PROFILE,
      IS_MOCK: false,
    },
    TAXONOMY: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.CANDIDATE_TAXONOMY,
      IS_MOCK: false,
    },
    NOTES: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.CANDIDATE_NOTES,
      IS_MOCK: false,
    },
    WIDGETS: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.CANDIDATE_WIDGETS,
      IS_MOCK: false,
    },
    MEETINGS: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.CANDIDATE_MEETINGS,
      IS_MOCK: false,
    },
    DOCUMENTS: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.CANDIDATE_DOCUMENTS,
      IS_MOCK: false,
    },
    TAGGINGS: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.CANDIDATE_TAGGINGS,
      IS_MOCK: false,
    },
    VACANCIES: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.CANDIDATE_VACANCIES,
      IS_MOCK: false,
    },
    ATTACH_TO_VACANCIES: {
      METHOD: "POST",
      URL: ROUTES.CANDIDATES.ATTACH_TO_VACANCY,
      IS_MOCK: false,
    },
    CANDIDATE_SHOW_VACANCY_PROFILE: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.SHOW_VACANCY_PROFILE,
      IS_MOCK: false,
    },
    CANDIDATE_CREATE_GROUP: {
      METHOD: "POST",
      URL: ROUTES.CANDIDATES.CREATE_GROUP,
      IS_MOCK: false,
    },
    CANDIDATE_CREATE_GROUP_LIST: {
      METHOD: "GET",
      URL: ROUTES.CANDIDATES.CREATE_GROUP_LIST,
      IS_MOCK: false,
    },
    CANDIDATE_EXPERIENCE: {
      METHOD: "POST",
      URL: ROUTES.CANDIDATES.ADD_CANDIDATE_EXPERIENCE,
      IS_MOCK: false,
    },
    DELETE_CANDIDATE_EXPERIENCE: {
      METHOD: "DELETE",
      URL: ROUTES.CANDIDATES.DELETE_CANDIDATE_EXPERIENCE,
      IS_MOCK: false,
    },
    EDIT_CANDIDATE_EXPERIENCE: {
      METHOD: "PATCH",
      URL: ROUTES.CANDIDATES.EDIT_CANDIDATE_EXPERIENCE,
      IS_MOCK: false,
    },
    CANDIDATE_EDUCATION: {
      METHOD: "POST",
      URL: ROUTES.CANDIDATES.ADD_CANDIDATE_EDUCATION,
      IS_MOCK: false,
    },
    DELETE_CANDIDATE_EDUCTION: {
      METHOD: "DELETE",
      URL: ROUTES.CANDIDATES.DELETE_CANDIDATE_EDUCATION,
      IS_MOCK: false,
    },
    EDIT_CANDIDATE_EDUCATION: {
      METHOD: "PATCH",
      URL: ROUTES.CANDIDATES.EDIT_CANDIDATE_EDUCATION,
      IS_MOCK: false,
    },
    EDIT_CANDIDATE: {
      METHOD: "PATCH",
      URL: ROUTES.CANDIDATES.EDIT_CANDIDATE,
      IS_MOCK: false,
    },
  },
  TEAM_MEMBER: {
    LISTING: {
      METHOD: "GET",
      URL: TEAM_MEMBER_PROFILE_ROUTE.TEAM_MEMBER_PROFILE,
      IS_MOCK: false,
    },
  },
  MASTER_ROUTES: {
    DESIGNATION: {
      LISTING: {
        METHOD: "GET",
        URL: MASTER_LEVEL_ROUTES.DESIGNATIONS.LISTING,
        IS_MOCK: false,
      },
    },

    GENDERS: {
      LISTING: {
        METHOD: "GET",
        URL: MASTER_LEVEL_ROUTES.GLOBAL.GENDER.LISTING,
        IS_MOCK: false,
      },
    },
    SITES: {
      LISTING: {
        METHOD: "GET",
        URL: MASTER_LEVEL_ROUTES.SITES.LISTING,
        IS_MOCK: false,
      },
    },
    CURRENCY: {
      LISTING: {
        METHOD: "GET",
        URL: MASTER_LEVEL_ROUTES.CURRENCY.LISTING,
        IS_MOCK: false,
      },
    },
    COUTRIES: {
      LISTING: {
        METHOD: "GET",
        URL: MASTER_LEVEL_ROUTES.LOCATION.COUNTIES.LISTING,
        IS_MOCK: false,
      },
    },
    STATES: {
      LISTING: {
        METHOD: "GET",
        URL: MASTER_LEVEL_ROUTES.LOCATION.STATES.LISTING,
        IS_MOCK: false,
      },
    },
    CITIES: {
      LISTING: {
        METHOD: "GET",
        URL: MASTER_LEVEL_ROUTES.LOCATION.CITIES.LISTING,
        IS_MOCK: false,
      },
    },
    REGIONS: {
      LISTING: {
        METHOD: "GET",
        URL: MASTER_LEVEL_ROUTES.LOCATION.REGIONS.LISTING,
        IS_MOCK: false,
      },
    },
    PREFERENCE: {
      LEVEL: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.PREFERENCE.LEVEL.LISTING,
          IS_MOCK: false,
        },
      },
      FUNCTION_GROUP: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.PREFERENCE.FUNCTION_GROUP.LISTING,
          IS_MOCK: false,
        },
      },
      FUNCTION: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.PREFERENCE.FUNCTION.LISTING,
          IS_MOCK: false,
        },
      },
      SUB_FUNCTION: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.PREFERENCE.SUB_FUNCTION.LISTING,
          IS_MOCK: false,
        },
      },
      SUB_SUB_FUNCTION: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.PREFERENCE.SUB_SUB_FUNCTION.LISTING,
          IS_MOCK: false,
        },
      },
      PRODUCT: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.PREFERENCE.PRODUCT.LISTING,
          IS_MOCK: false,
        },
      },
      VERTICAL: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.PREFERENCE.VERTICAL.LISTING,
          IS_MOCK: false,
        },
        PRACTICE_AREA: {
          LISTING: {
            METHOD: "GET",
            URL: MASTER_LEVEL_ROUTES.PREFERENCE.PRACTICE_AREA.LISTING,
            IS_MOCK: false,
          },
        },
        SUB_PRACTICE_AREA: {
          LISTING: {
            METHOD: "GET",
            URL: MASTER_LEVEL_ROUTES.PREFERENCE.SUB_PRACTICE.LISTING,
            IS_MOCK: false,
          },
        },
      },
      SEGMENT: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.PREFERENCE.SEGMENT.LISTING,
          IS_MOCK: false,
        },
      },
    },
    VACANCY: {
      STAGE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.STATGE.LISTING,
          IS_MOCK: false,
        },
      },
      VACANCY_TYPE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.VACANCY_TYPE.LISTING,
          IS_MOCK: false,
        },
      },
      VACANCY_STATUS: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.VACANCY_STATUS.LISTING,
          IS_MOCK: false,
        },
      },
      DEPARTMENTS: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.DEPARTMENTS.LISTING,
          IS_MOCK: false,
        },
      },
      PROCEDURE_TYPE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.PROCEDURE_TYPE.LISTING,
          IS_MOCK: false,
        },
      },
      PROCEDURE_GROUP: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.PROCEDURE_GROUP.LISTING,
          IS_MOCK: false,
        },
      },
      INTERVIEW_TYPE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.INTERVIEW_TYPE.LISTING,
          IS_MOCK: false,
        },
      },
      TRACKER_TYPE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.TRACKER_TYPE.LISTING,
          IS_MOCK: false,
        },
      },
      REMINDER_TYPE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.REMINDER_TYPE.LISTING,
          IS_MOCK: false,
        },
      },
      RM_STATUS: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.RM_STATUS.LISTING,
          IS_MOCK: false,
        },
      },
      SECTOR: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.SECTOR.LISTING,
          IS_MOCK: false,
        },
      },
      INDUSTRY: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.VACANCY.INDUSTRY.LISTING,
          IS_MOCK: false,
        },
      },
    },
    TEAMS: {
      ROLE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.TEAMS.ROLES.LISTING,
          IS_MOCK: false,
        },
      },
      // DESIGNATION:{
      //   LISTING: {
      //     METHOD: 'GET',
      //     URL: MASTER_LEVEL_ROUTES.TEAMS.ROLES.LISTING,
      //     IS_MOCK: false
      //   },
      // },
      TEAM: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.TEAMS.TEAMS.LISTING,
          IS_MOCK: false,
        },
      },
    },
    RESUME: {
      RESUME_TYPE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.RESUME.RESUME_TYPE.LISTING,
          IS_MOCK: false,
        },
      },
      EDUCATION_DEGREE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.RESUME.EDUCATION_DEGREE.LISTING,
          IS_MOCK: false,
        },
      },
      EDUCATION_LEVEL: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.RESUME.EDUCATION_LEVEL.LISTING,
          IS_MOCK: false,
        },
      },
      EDUCATION_INSTITUTE: {
        LISTING: {
          METHOD: "GET",
          URL: MASTER_LEVEL_ROUTES.RESUME.EDUCATION_INSTITUTE.LISTING,
          IS_MOCK: false,
        },
      },
    },
  },
  TEAMS: {
    LISTING: {
      METHOD: "GET",
      URL: MASTER_LEVEL_ROUTES.TEAMS.TEAMS.LISTING,
      IS_MOCK: false,
    },
  },
  USERS: {
    LISTING: {
      METHOD: "GET",
      URL: TEAM_LISTING_ROUTES.TEAM_LISTING,
      IS_MOCK: false,
    },
  },
  RESUME: {
    LISTING: {
      METHOD: "GET",
      URL: MASTER_LEVEL_ROUTES.RESUME.RESUME_TYPE.LISTING,
      IS_MOCK: false,
    },
  },

  EDU_LEVEL: {
    LISTING: {
      METHOD: "GET",
      URL: MASTER_LEVEL_ROUTES.RESUME.EDUCATION_LEVEL.LISTING,
      IS_MOCK: false,
    },
  },
  EDU_DEGREE: {
    LISTING: {
      METHOD: "GET",
      URL: MASTER_LEVEL_ROUTES.RESUME.EDUCATION_DEGREE.LISTING,
      IS_MOCK: false,
    },
  },
  EDU_INSTITUTE: {
    LISTING: {
      METHOD: "GET",
      URL: MASTER_LEVEL_ROUTES.RESUME.EDUCATION_INSTITUTE.LISTING,
      IS_MOCK: false,
    },
  },
  ACCOUNTS: {
    LISTING: {
      METHOD: "GET",
      URL: ROUTES.ACCOUNTS.LIST_ACCOUNT,
      IS_MOCK: false,
    },
    CREATE: {
      METHOD: "POST",
      URL: ROUTES.ACCOUNTS.CREATE_ACCOUNT,
      IS_MOCK: false,
    },
  },
  ACCOUNTS_TAXONOMY: {
    TAXONOMY: {
      METHOD: "GET",
      URL: ROUTES.ACCOUNTS.ACCOUNTS_TAXONOMY,
      IS_MOCK: false,
    },
  },
  ACCOUNTS_PROFILE: {
    PROFILE: {
      METHOD: "GET",
      URL: ROUTES.ACCOUNTS.ACCOUNTS_PROFILE,
      IS_MOCK: false,
    },
  },
  ACCOUNTS_MEETINGS: {
    MEETINGS: {
      METHOD: "GET",
      URL: ROUTES.ACCOUNTS.ACCOUNT_MEETINGS,
      IS_MOCK: false,
    },
  },
  ACCOUNTS_DOCUMENTS: {
    DOCUMENTS: {
      METHOD: "GET",
      URL: ROUTES.ACCOUNTS.ACCOUNT_DOCUMENTS,
      IS_MOCK: false,
    },
  },
  ACCOUNTS_TERMS: {
    TERMS: {
      METHOD: "GET",
      URL: ROUTES.ACCOUNTS.ACCOUNT_TERMS,
      IS_MOCK: false,
    },
  },
  ACCOUNTS_VACANCIES: {
    VACANCIES: {
      METHOD: "GET",
      URL: ROUTES.ACCOUNTS.ACCOUNT_VACANCIES,
      IS_MOCK: false,
    },
  },
  ACCOUNTS_WIDGETS: {
    WIDGETS: {
      METHOD: "GET",
      URL: ROUTES.ACCOUNTS.ACCOUNT_WIDGETS,
      IS_MOCK: false,
    },
  },
  ADD_SUBTEAM: {
    METHOD: "POST",
    URL: ROUTES.ACCOUNTS.ADD_SUBTEAM,
    IS_MOCK: false,
  },
  ADD_ENTITY: {
    METHOD: "POST",
    URL: ROUTES.ACCOUNTS.ADD_ENTITY,
    IS_MOCK: false,
  },
  ADD_OFFICE: {
    METHOD: "POST",
    URL: ROUTES.ACCOUNTS.ADD_OFFICE,
    IS_MOCK: false,
  },
  ADD_TERMS: {
    METHOD: "POST",
    URL: ROUTES.ACCOUNTS.ADD_TERMS,
    IS_MOCK: false,
  },
  
  CLIENT_PERSON: {
    LISTING: {
      METHOD: "GET",
      URL: ROUTES.CLIENT_PERSON.LIST_CLIENT_PERSON,
      IS_MOCK: false,
    },
    CREATE: {
      METHOD: "POST",
      URL: ROUTES.CLIENT_PERSON.CREATE_CLIENT_PERSON,
      IS_MOCK: false,
    },
    PROFILE: {
      METHOD: "GET",
      URL: ROUTES.CLIENT_PERSON.CLIENT_PERSON_PROFILE,
      IS_MOCK: false,
    },
    NOTES: {
      METHOD: "GET",
      URL: ROUTES.CLIENT_PERSON.CLIENT_PERSON_NOTES,
      IS_MOCK: false,
    },
    MEETINGS: {
      METHOD: "GET",
      URL: ROUTES.CLIENT_PERSON.CLIENT_PERSON_MEETINGS,
      IS_MOCK: false,
    },
  },
  DASHBOARD: {
    LIST_VACANCY_STATISTICS: {
      METHOD: "GET",
      URL: ROUTES.DASHBOARD.VACANCY_STATISTICS,
      IS_MOCK: false,
    },
    LIST_VACANCIES_DASHBOARD: {
      METHOD: "GET",
      URL: ROUTES.DASHBOARD.VACANCIES_LIST,
      IS_MOCK: false,
    },
  },
  MEETINGS: {
    LISTING: {
      METHOD: "POST",
      URL: ROUTES.MEETINGS.LISTING,
      IS_MOCK: false,
    },
  },
  INVOICE: {
    LISTING: {
      METHOD: "GET",
      URL: ROUTES.INVOICE.INVOICE_LISTING,
      IS_MOCK: false,
    },
    PROFILE: {
      METHOD: "GET",
      URL: ROUTES.INVOICE.INVOICE_PROFILE,
      IS_MOCK: false,
    },
    PAYMENT: {
      METHOD: "POST",
      URL: ROUTES.INVOICE.RECEIVE_PAYMENT,
      IS_MOCK: false,
    },
  },
  REPORTS: {
    DASHBOARD: {
      METHOD: "POST",
      URL: ROUTES.REPORTS.DASHBOARD_REPORTS,
      IS_MOCK: false,
    },
    RESUME_REVIEW: {
      METHOD: "POST",
      URL: ROUTES.REPORTS.RESUME_REVIEW_REPORTS,
      IS_MOCK: false,
    },
    LANDSCAPE: {
      METHOD: "POST",
      URL: ROUTES.REPORTS.LANDSCAPE_REPORTS,
      IS_MOCK: false,
    },
  },
  SIGNIN: {
    METHOD: "POST",
      URL: ROUTES.AUTH.SIGNIN,
      IS_MOCK: false,
    },
  SIGNOUT: {
    METHOD: "POST",
      URL: ROUTES.AUTH.SIGNOUT,
      IS_MOCK: false,
    },
  TRAGETS: {
    LISTING: {
      METHOD: "GET",
      URL: ROUTES.TARGET.TARGET_LISTING,
      IS_MOCK: false,
    }
  }
};

export default ROUTER;