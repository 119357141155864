import Services from "../../../services/apis";
import { message } from "ant-design-vue";

const state = () => ({
  selectedCandidatesForVacancy: [],
  vacancyId: "",
  companyId: "",
  candidateId: "",
  resumeId: "",
  termId: "",
  candidateName: ""
});

const mutations = {
  addSelectedCandidates(state, selectedCandidates) {
    state.selectedCandidatesForVacancy.splice(0);
    state.selectedCandidatesForVacancy = selectedCandidates;
  },
  updateChangeStageStatus(state, status) {
    state.changeSatgeStatus = status;
  },
  updateVacancyId(state, vacancyId) {
    state.vacancyId = vacancyId;
  },
  setCompanyId(state, companyId) {
    state.companyId = companyId;
  },
  setCandidateId(state, candidateId) {
    state.candidateId = candidateId;
  },
  setResumeId(state, resumeId) {
    state.resumeId = resumeId;
  },
  setRevenueTermId(state, termId) {
    state.termId = termId;
  },
  setCandidateName(state, candidateName){
    state.candidateName = candidateName;
  }

};

const actions = {
  // Vacancy Profile Selected candidates from candidate list
  setSelectedCandidates({ commit }, selectedCandidates) {
    commit("addSelectedCandidates", selectedCandidates);
  },

  setVacancyId({ commit }, vacancyId) {
    commit("updateVacancyId", vacancyId);
  },

  attachToVacancy({ state, commit, getters }) {
    let id = state.vacancyId;
    let arrayObjct = [];
    arrayObjct = state.selectedCandidatesForVacancy.map((x) => {
      let tempObject = {
        candidateId: x,
        procedureStageId: "5a72702e-9f5e-43a0-bc84-d41d75a09809",
      };
      return tempObject;
    });
    let payload = {
      id: id,
      data: arrayObjct,
    };
    Services.attachToVacancy(payload)
      .then((response) => {
        const { data } = response;

        arrayObjct = [];

        message.success("Candidates are successfully attached to a vacancy");
        state.selectedCandidates = "";
      })
      .catch((e) => {
        console.log("error :>> ", e);
      });
  },
  updateCompanyId({ state, commit }, companyId) {
    commit("setCompanyId", companyId);
  },
  vacancyProfileCandidateId({ state, commit }, candidateId) {
    commit("setCandidateId", candidateId);
  },
  vacancyProfileResumeId({ state, commit }, resumeId) {
    commit("setResumeId", resumeId);
  },
  vacancyRevenueTermId({state, commit}, termId){
      commit("setRevenueTermId", termId);
  },
  vacancyRevenueCandidateName({state, commit}, candidateName){
    commit("setCandidateName", candidateName);
}
};

const getters = {
  getVacancyId: (state) => {
    return state.vacancyId;
  },
  getSelectedCandidates: (state) => {
    return state.selectedCandidatesForVacancy;
  },
  getCompanyId: (state) => {
    return state.companyId;
  },
  getCandidateId: (state) => {
    return state.candidateId;
  },
  getResumeId: (state) => {
    return state.resumeId;
  },
  getTermId: (state) => {
    return state.termId;
  },
  getCandidateName: (state) => {
    return state.candidateName;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
